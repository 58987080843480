import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getSearchParamsFromUrl } from '../../../js/document/url'

const widgetApi = 'w-login-form'

const widgetQueries = {
  currentView: `[data-${widgetApi}__modal]`,
  switch: `data-${widgetApi}__switch-to`,
  currentViewAttribute: `data-${widgetApi}__current-view`,
  mainNewsletterCheckbox: `[data-${widgetApi}__main-newsletter] input[type="checkbox"]`,
  secondaryNewsletterContainer: `[data-${widgetApi}__secondary-newsletter]`
}

widgetQueries.secondaryNewsletterCheckboxes = `${widgetQueries.secondaryNewsletterContainer} input[type="checkbox"]`

const queryParams = {
  view: 'view'
}

const supportedViews = {
  default: 'login',
  others: [
    'register',
    'password-forgoten'
  ]
}

export default class LoginForm {
  /**
   * Creates a new Login form widget
   *
   * @constructor
   *
   * @param {HTMLElement} element -
   */
  constructor (element) {
    if (!element) { return }
    this.element = element
    this._initCurrentView()
    this._getHtmlElements()
    this._bindEvents()
  }

  _getHtmlElements () {
    this.switchers = this._initSwitchers()
    this.mainNewsletterCheckbox = this.element.querySelector(widgetQueries.mainNewsletterCheckbox)
    this.secondaryNewsletterContainer = this.element.querySelector(widgetQueries.secondaryNewsletterContainer)
    this.secondaryNewsletterCheckboxes = this.element.querySelectorAll(widgetQueries.secondaryNewsletterCheckboxes)
  }

  _bindEvents () {
    this.switchers.forEach(function (switcher) {
      switcher.element.addEventListener('click', e => { this._handleSwitch(e, switcher) }, false)
    }.bind(this))
    if (this.mainNewsletterCheckbox && this.secondaryNewsletterContainer) {
      this.mainNewsletterCheckbox.addEventListener('change', this._handleMainNewsletterChange.bind(this))
      this.secondaryNewsletterCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', this._handleSecondaryNewsletterChange.bind(this))
      })
    }
  }

  _initCurrentView () {
    const parameters = getSearchParamsFromUrl(document.location)
    if (parameters) {
      const currentView = parameters[queryParams.view]
      if (currentView) {
        this._setCurrentView(currentView)
      }
    }
  }

  _setCurrentView (view) {
    const currentView = supportedViews.others.includes(view) ? view : supportedViews.default
    this.element.setAttribute(widgetQueries.currentViewAttribute, currentView)
  }

  _initSwitchers () {
    const switchers = []

    const elements = this.element.querySelectorAll(`[${widgetQueries.switch}]`)
    elements.forEach(el => {
      switchers.push({
        element: el,
        toViewName: el.getAttribute(widgetQueries.switch)
      })
    })

    return switchers
  }

  _handleSwitch (ev, switcher) {
    ev.preventDefault()
    this._setCurrentView(switcher.toViewName)
  }

  _handleMainNewsletterChange () {
    const isChecked = this.mainNewsletterCheckbox.checked
    this.secondaryNewsletterContainer.style.display = isChecked ? 'block' : 'none'
    this.secondaryNewsletterCheckboxes.forEach(checkbox => (checkbox.checked = isChecked))
  }

  _handleSecondaryNewsletterChange () {
    const allUnchecked = Array.from(this.secondaryNewsletterCheckboxes).every(checkbox => !checkbox.checked)
    if (allUnchecked) {
      this.mainNewsletterCheckbox.checked = false
      this.secondaryNewsletterContainer.style.display = 'none'
    }
  }
}

registerWidget(LoginForm, widgetApi)
